@import "https://fonts.googleapis.com/css?family=Quicksand:400,500";

.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light {
  color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

$brandOrange: #ee6500;
$mutedText: #4a4a4a;

body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;

  a {
    font-weight: 500;
    color: $brandOrange;
    text-decoration: none; } }

#bunnynav {
  margin-left: auto;
  margin-right: auto;
  margin-top: 72px;
  margin-bottom: 56px;
  width: 55px;
  height: 78px; }

#bunny {
  text-align: center; }

#bunny_image {
  max-width: 100%;
  height: auto !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }

#bunnyicon {
  background: url("/assets/images/rabbit-refresh.svg") no-repeat;
  width: 62px;
  height: 78px; }

#bunnyicon:hover {
  background: url("/assets/images/rabbit-refresh-hover.svg") no-repeat; }

#bunny_source {
  margin-top: 24px;
  padding: 0; }

#support {
  margin-top: 64px;
  color: $mutedText; }

#bunny_stats {
  background: $brandOrange;
  color: white;
  font-size: 30px;
  $letterSpacing: 11px;
  letter-spacing: $letterSpacing;
  padding-left: 64px;
  padding-right: 64px - $letterSpacing;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); }

#bunny_stats_container {
  text-align: center;
  margin-top: 76px;
  margin-bottom: 64px; }

#bunnies_served {
  padding-top: 14px;
  font-size: 14px;
  color: $mutedText; }

#animals_flavour_text {
  width: 300px;
  color: lighten($mutedText, 30%);
  margin-left: auto;
  margin-right: auto; }

.footer_vertical_separator {
  color: #e5e5e5;
  margin-left: 10px;
  margin-right: 10px; }

.selectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer; }

.noclick {
  cursor: default; }
